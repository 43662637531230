<template>
  <div class="core-container">
    <a-row justify="center" :wrap="true">
      <a-col :span="4" style="padding: 0 10vw;" v-for="{ title, color, size, image, link, img_size } in data_list">
          <a v-if="link" :href="link">
            <span :style="`color: ${color};font-size: ${size}`">{{ title }}</span>
            <img :src="image" :style="`${img_size? img_size:'width: 100%'}`" />
          </a>
          <div v-else>
            <span :style="`color: ${color};font-size: ${size}`">{{ title }}</span>
            <img :src="image" :style="`${img_size? img_size:'width: 100%'}`" />
          </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'CoreContainer',
  props: {
    data_list: Array,
  }
}
</script>
<style scoped lang="scss">
</style>
