<template>
  <a-layout>
    <a-spin v-if="loading" />
    <CoreContainer 
      :data_list="data_list"
    />
  </a-layout>
</template>

<script>
import CoreContainer from '@/components/CoreContainer.vue';

export default {
  name: 'Home',
  props: {
    loading: Boolean,
    data_list: Array,
    options: Object
  },
  components: {
    CoreContainer
  }
}
</script>
