<template>
  <div id="app" :style="`background: ${options.background}; font-family: ${options.font};`">
    <router-view
      :data_list="data_list"
      :options="options"
      :loading="loading"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { GoogleSpreadsheet } from 'google-spreadsheet';

export default {
  name: 'Home',
  data () {
    return {
      loading: true,
      data_list: [
      ],
      options: {
        background: 'black',
        font: 'Avenir, Helvetica, Arial, sans-serif',
        meta: {
          title: 'Meta Title',
          description: 'Meta Description'
        }
      },
      PRIVATE_KEY: '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCyLlyuUBKCaG5R\nm80w+grov79Ezl8fSTBB53MPO5M6wDhjZS/MeRYjAqpUmzq4aA/GxqLCWNq/CTAP\nUaWLv1kZQEkV5QDfwI1Wop8HY7nwja+EiaZg9pe/2UEMfn3yq3vzJzJOQwGmnfE8\nUHnWtZZfmFWxnXKJHbDaoKufhbs1SLhZa8K/fZJgS+96TH4zu5qxWh/OwHRQkZOC\np61Yfpicxe/CegRwnKbRE9ql3wdDv+1k0SsAxUbEOphXiCXA4vu+oqhghHw3gn2j\nTa/OJY+umiB3OsIQNxrGGFbwWBwsQP2mdSpu9VlirD0dBmnkJqU40tE4v7FOcHu9\nGoHMk18BAgMBAAECggEABm8bJKY6jN/1f9fm6GpXA4GNCBCFqc0MQ4z2gCnQDrKL\nNrecKr+Cl6AMg4KZc7g6PZzFmsv3lidgR2VU23982acTHInI5st+rw6vPGaS+8c5\n+8i2q9CruR9AtJFKvf0IPEcBj4bm49vXLqzfQWwMMoZ/Las4TBA45yQfHmMHWAyp\nKSq4JwNKnajuj0KoIwIqSWA2rVXDWFj4wbu3Qprjh+bP6GXDoCAfgEQHnfKgfkKe\nZeK1iMv7AL/RJehADHlACQlPs5YrehKGMrpby2awqVgeHChFojMCLlXUq0V21vTT\nRcGANKPuZyUjKVRpaAU3PuWJm2kB+etp7vyZy6POewKBgQDvXUKGLyIEiU3Bz6xO\nNRho9K8hR/4Ws1mg+S8b6PzxEaddMklG4DOaw0XcOEhH8s7u4PRQaFYyM2+CHbN9\nZeRzjF0zlq1F939aBAl59ijWC+Go0bB/O1CpgZ72LSZl3w50v/CXYN8dOSjkT6o6\nuFZVzT1bdDfRR3CwdnqttdhwjwKBgQC+kInNxBJ3gbDBFyhWwhR6NfzurFtqh36v\nAzoK7/M5qJ1SciccOwg3Hsj58PFn3hmLFxRbbuR5QNDwgIj+K109XHoO84Cp6zOg\nifMTTqicbJog96iuapnrodRMyvfvDH1sHd/vC0JrpLjOnC+XBy7GE+4cQddJ/oTr\n2QmmFnLfbwKBgQC/Ebi6mAEVkeyp2vZ171gu37K0ZF1lDYFsR7zdEJxoamkosa1g\nkDNhRKMNaTf5hs68TIF5de/oXrqSKNdQL/eubMsxFQP3p9rWpgZSfraWNiHJuqii\nx1xaEogrhlryUp2dgGGy1LcFAs2rghQmj2MLVqu1xw2PVvGTSuAerdu57QKBgQCm\nuJvnqwghCBUVCeaf6NKzjTJJuf+Mtxhtk5wfX4jjRBLHw9lVyIvKdaONkHAT2Gtu\nlfWYfO9k6gEiuMGh5VyAx7x+Ainp/z2+0ZIZrTK1EArUlvg8CEW7smyj9ttth/bf\nNU/qumYU+Rs6n8i/EGiqoVsr26q4MPYnxU28wl0j/wKBgCRP6/IHxNDH1SzdNzma\n7BUzP45EEPWS2A4HSfArjKVQ09Y4+Q19WdKMxmLud3Ipa88MbVxTjeR9WdFWOML0\nDgBoQEnqJPQLHpjhK5yfJbM4bhx7+quAlDC/aLWchVYNW7SzY4cA95E8E4R4SAy/\n0EuQzBtQ80w+3KAsNP+X/ucd\n-----END PRIVATE KEY-----\n',
      CLIENT_EMAIL: 'seo-portal-example@marnage.iam.gserviceaccount.com',
      SHEET_ID: '1Y-htdXSnmrCx9f6W0y7gbELso7JgTR54XsbO9BzlS_Q'
    }
  },
  methods: {
    convert_to_option({ data_list, options }) {
      this.data_list = data_list.map(({ color, image, link, size, title, img_size }) => {
        return { color, image, link, size, title, img_size }
      })
      this.options = options.map(({ background, font, meta_title, meta_description }) => {
        return { background, font, meta: { title: meta_title, description: meta_description } }
      })[0]
      
      // document.title = this.options.meta.title
      // document.querySelector('meta[name="description"]').setAttribute('content', this.options.meta.description)
    },
    async get_sheet(sheet_id) {
      const doc = new GoogleSpreadsheet(sheet_id);

      await doc.useServiceAccountAuth({
        client_email: this.CLIENT_EMAIL,
        private_key: this.PRIVATE_KEY
      });

      await doc.loadInfo(); // loads document properties and worksheets

      const data_list = await doc.sheetsByTitle['data_list'].getRows();
      const options = await doc.sheetsByTitle['options'].getRows();

      this.convert_to_option({
        data_list: data_list,
        options: options
      })
      
      this.loading = false
    }
  },
  async created() {
    return await this.get_sheet(this.SHEET_ID)
  }
}
</script>

<style lang="scss">
a {
  text-decoration: none;
}
html, #app {
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  text-align: center;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  img {
    max-width: 80vw
  }
}
</style>
